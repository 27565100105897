import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AgeFilterComponent } from './pages/age-filter/age-filter.component';
import { HomeComponent } from './pages/home/home.component';
import { TermsComponent } from './pages/terms/terms.component';

const routes: Routes = [
  { path: '', component: AgeFilterComponent, pathMatch: 'full' },
  { path: 'inicio', component: HomeComponent },
  { path: 'terminos-y-condiciones', component: TermsComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
