<div class="background">
    <div class="main">
        <div class="logos">
            <img src="../../../assets/img/age-filter/iqos_logo_edad.webp" alt="IQOS" width="auto" height="auto"
                class="iqos">
            <img src="../../../assets/img/age-filter/logo_oxxo_m.webp" alt="OXXO" width="auto" height="auto"
                class="oxxo">
            <h2>Debes ser mayor de edad para ingresar a este sitio.</h2>
        </div>
        <div class="elements">
            <input id="date" type="date" title="bd" class="age-input" value="date" min="1900-01-01" max="2020-12-31"
                (change)="isAgeInputEmpty()">
            <button class="age-button" type="button" [disabled]="isAgeInputEmpty()"
                (click)="checkAge()">Confirmar</button>
        </div>
        <div class="texts">
            <p>Este sitio contiene información acerca de productos sin combustión y necesitamos conocer tu edad para
                asegurarnos que eres adulto fumador o usuario adulto de otros productos con nicotina y residente en
                México.</p>
            <p>Los productos de tabaco y con nicotina no son una alternativa para dejar de fumar y no están diseñados
                como ayuda para la cesación. No son productos libres de riesgo y contienen nicotina la cual es adictiva.
                Su uso es exclusivo para adultos.</p>
        </div>
    </div>
    <div class="cookies" [hidden]="noCookies">
        <p class="cookies-text">Utilizamos cookies para brindarte una experiencia personalizada (para que se adapten a
            tu comportamiento en línea en este y otros sitios) para nuestros anuncios, contenido y comunicación; para
            mejorar el sitio; para operar el sitio; y para recordar tus preferencias. Puedes cambiar de opinión en
            cualquier momento visitando &quot;preferencias de cookies&quot;. Cualquier dato personal se utilizará como
            se describe en nuestro <a href="https://www.oxxo.com/aviso-de-privacidad-integral" target="_blank"
                class="link">Aviso de privacidad</a>. </p>
        <button class="cookies-button" (click)="hideCookies()">Aceptar todas las cookies</button>
    </div>
    <div [hidden]="isWarningActive()" class="age-error"></div>
    <button type="button" title="error" [hidden]="isWarningActive()" (click)="closeWarning()">
        <img src="../../../assets/img//age-filter/error_edad.webp" alt="Error | Cerrar" width="auto" height="auto"
            class="err-img">
    </button>
</div>