<header>
    <a href="https://www.oxxo.com" target="_blank">
        <img id="oxxo-head" src="../../../assets/img/main/oxxo_logo_s.webp" alt="OXXO" width="auto" height="auto"
            loading="lazy">
    </a>
    <img id="iqos-head" src="../../../assets/img/main/iqos_logo_s.webp" alt="IQOS" width="auto" height="auto"
        loading="lazy">
</header>
<nav>
    <a id="nav-inicio" href="/inicio#" class="nav-link">Inicio</a>
    <a id="nav-registro" href="/inicio#Registro" class="nav-link">Registro</a>
    <a id="nav-tiendas" href="/inicio#Tiendas" class="nav-link">Localizador de Tiendas</a>
</nav>
<article id="Inicio">
    <main>
        <section id="main-info">
            <div>
                <h1>Registra tu</h1>
                <img id="iqos-inicio" src="../../../assets/img/main/iqos_iluma.webp" alt="IQOS" width="auto"
                    height="auto" loading="lazy">
                <h1>y recibe 2,500 puntos Spin Premia</h1>
                <h4>*Información importante: IQOS es un dispositivo electrónico que, al calentar unidades de tabaco,
                    entrega nicotina, la cual es adictiva y no está libre de riesgo. Prohibida su venta a menores de 18
                    años.</h4>
            </div>
            <img id="iqos-device" src="../../../assets/img/main/producto_prueba.webp" alt="IQOS" width="auto"
                height="auto" loading="lazy">
        </section>
    </main>
    <section id="info-inicio">
        <h2>¿Cuáles son los beneficios?</h2>
        <p>Garantía por un año, cobertura de daños accidentales, promociones especiales, información sobre eventos
            exclusivos y muchos más beneficios.</p>
        <a href="/inicio#Registro">
            <button id="register-button">Registra tu IQOS</button>
        </a>
    </section>
</article>
<article id="Registro">
    <h1>‎</h1>
    <div id="register-main">
        <section>
            <h1>Registra aquí tu</h1>
            <img id="register-iqos" src="../../../assets/img/register/iqos_iluma_dark.webp" alt="IQOS" width="auto"
                height="auto" loading="lazy">
            <p>¡Accede a los beneficios exclusivos que IQOS ILUMA tiene para ti! Garantía por un año, cobertura de daños
                accidentales, promociones especiales, información sobre eventos exclusivos y muchos beneficios más.</p>
            <p>Al registrarte recibe 2,500 puntos Spin Premia para comprar productos en OXXO.*</p>
        </section>
        <section>
            <form id="register-form" [hidden]="!noWarning" (submit)="handleSubmit($event)">
                <h5>‎</h5>
                <h3 id="first-field" class="field-name">Nombre Completo</h3>
                <input id="name" type="text" name="name" autocomplete="off" maxlength="60" pattern="[a-z A-Z]+"
                    class="field" required>
                <h3 class="field-name">Correo que registraste en Spin Premia</h3>
                <input id="email" type="email" name="email" autocomplete="off" class="field" required>
                <h3 class="field-name">Teléfono que registraste en Spin Premia</h3>
                <input id="phone" type="tel" name="phone" autocomplete="off" maxlength="10" pattern="[0-9]{10}"
                    class="field" required>
                <h3 class="field-name">Código de dispositivo IQOS ILUMA (sin espacios y en mayúsculas)</h3>
                <div>
                    <input id="input-code" type="text" name="device_id" autocomplete="off" pattern="[A-Za-z0-9]{12}"
                        maxlength="12" style="text-transform:uppercase" autocapitalize="characters" class="field"
                        required>
                    <a (click)="showWarning('code')">
                        <img id="code-button" src="../../../assets/img/register/boton_buscar.webp" alt="Buscar"
                            width="auto" height="auto" loading="lazy" title="Ubica el código de tu dispositivo">
                    </a>
                </div>
                <div id="terms">
                    <input type="checkbox" required>
                    <h5 id="terms-text">Al registrarse, usted acepta los <a href="/terminos-y-condiciones"
                            target="_blank">Términos y Condiciones</a> y otorga su consentimiento para que sus datos
                        personales sean tratados de acuerdo a nuestro <a
                            href="https://www.oxxo.com/aviso-de-privacidad-integral" target="_blank">Aviso de
                            privacidad</a>. </h5>
                </div>
                <button id="submit-button" type="submit">Registrar</button>
            </form>
            <div id="warning" [hidden]="noWarning">
                <a (click)="closeWarning()">
                    <h1 id="loading-msg" [hidden]="noLoadingWarning">Espera un momento...</h1>
                    <img src="../../../assets/img/register/codigo.webp" alt="Cerrar" width="auto" height="auto"
                        loading="lazy" [hidden]="noCodeWarning" class="pop-img">
                    <img src="../../../assets/img/register/member.webp" alt="Error" width="auto" height="auto"
                        loading="lazy" [hidden]="noMemberWarning" class="pop-img">
                    <img src="../../../assets/img/register/store.webp" alt="Error" width="auto" height="auto"
                        loading="lazy" [hidden]="noStoreWarning" class="pop-img">
                    <img src="../../../assets/img/register/register.webp" alt="Error" width="auto" height="auto"
                        loading="lazy" [hidden]="noRegisterWarning" class="pop-img">
                    <img src="../../../assets/img/register/error.webp" alt="Error" width="auto" height="auto"
                        loading="lazy" [hidden]="noErrorWarning" class="pop-img">
                    <img src="../../../assets/img/register/product.webp" alt="Error" width="auto" height="auto"
                        loading="lazy" [hidden]="noProductWarning" class="pop-img">
                    <img src="../../../assets/img/register/exito.webp" alt="Éxito" width="auto" height="auto"
                        loading="lazy" [hidden]="noSuccessWarning" class="pop-img">
                </a>
            </div>
        </section>
    </div>
    <section>
        <div id="app-info-main">
            <div id="app-info">
                <img src="../../../assets/img/register/app_spin.webp" alt="Spin Premia" width="auto" height="auto"
                    loading="lazy" class="spin-logo">
                <div>
                    <h2>¿No tienes la app?</h2>
                    <h2>¡Descárgala ya!</h2>
                </div>
            </div>
            <div id="app-info-logos">
                <a href="https://play.google.com/store/search?q=spin%20premia&c=apps" target="_blank">
                    <img src="../../../assets/img/register/google_play.webp" alt="Play Store" width="auto" height="auto"
                        loading="lazy">
                </a>
                <span></span>
                <a href="https://apps.apple.com/us/app/spin-premia/id6448477929" target="_blank">
                    <img src="../../../assets/img/register/app_store.webp" alt="App Store" width="auto" height="auto"
                        loading="lazy">
                </a>
            </div>
        </div>
        <h5>*Para la acumulación de puntos, es indispensable ser miembro del programa de lealtad Spin Premia y disponer
            de una tarjeta física o digital activa. En caso de no ser miembro, se deberá descargar la aplicación Spin
            Premia y completar el registro. Los puntos pueden ser reflejados hasta 7 días después del proceso de
            registro. Esta promoción aplica exclusivamente para dispositivos IQOS ILUMA adquiridos en tiendas OXXO. OXXO
            se deslinda de cualquier responsabilidad relacionada con fallas en el dispositivo o daños a la salud
            derivados de su uso.</h5>
    </section>
</article>
<article id="Tiendas">
    <section id="stores-text">
        <h1>¡Encuentra tu OXXO más cercano!</h1>
    </section>
    <section>
        <iframe src="https://conoce-iqos.com/wp-content/localizador-tiendas/oxxo.html" frameborder="0"
            style="display: none;" onload="this.style.display='block';"></iframe>
    </section>
</article>
<footer>
    <h2 id="contact">Contacto IQOS: <a id="phone-num" href="tel:8001476769" class="external-link">800 14 76 769</a>
    </h2>
    <p id="copyright">© Cadena Comercial OXXO, S.A. de C.V.</p>
    <p id="footer-links">
        <a href="https://www.oxxo.com/aviso-de-privacidad-integral" target="_blank" class="external-link">Aviso de
            Privacidad</a> | <a href="/terminos-y-condiciones" class="external-link">Términos y Condiciones</a>
    </p>
</footer>