import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AgeFilterComponent } from './pages/age-filter/age-filter.component';
import { TermsComponent } from './pages/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    AgeFilterComponent,
    TermsComponent,
  ],
  imports: [
    NgbModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [
    HttpClient,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
